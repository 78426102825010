import React from 'react'
import { Link, withPrefix } from 'gatsby'
import styled from 'styled-components'
import { Container, Row, Col } from 'reactstrap'

import { TitleWithLine, ButtonLink, ButtonLinkTransparent } from '../elements'

import contactBG from '../../images/teachers/contact_bg.svg'
import phoneIcon from '../../images/phone_green_icon.svg'
import emailIcon from '../../images/email_green_icon.svg'
import downloadIcon from '../../images/download_green_icon.svg'

const ContactWrapper = styled.div`
  background-image: url(${contactBG});
  background-repeat: no-repeat;
  background-position: top right;
  background-size: cover;
  padding-top: 50px;
  padding-bottom: 100px;
  @media (max-width: 575px) {
    padding-bottom: 40px;
  }
`

const ContactTitle = styled(TitleWithLine)``

const ContactDescOuterWrapper = styled(Col)`
  padding-top: 40px;
`

const ContactInnerTitle = styled(TitleWithLine)`
  position: relative !important;
  display: inline-block;
  text-transform: initial;
  span {
    position: static;
    display: inline-block;
    ${(props) =>
    props.color &&
    `
      color: ${props.color};
    `}
    &:after {
      left: 0;
      right: 35%;
      bottom: -27px;
    }
  }
`

const ContactDescWrapper = styled.div`
  font-size: 18px;
  font-weight: 300;
  @media (min-width: 1200px) and (max-width: 1399px) {
    padding-right: 20%;
  }
  @media (min-width: 576px) {
    padding-right: 30%;
  }
`

const ContactButtonLink = styled(ButtonLink)`
  margin-top: 25px;
  margin-right: 40px;
  @media (max-width: 575px) {
    font-size: 18px;
    padding-left: 40px;
    padding-right: 40px;
    margin-right: 15px;
  }
  &:hover {
    opacity: 0.9;
  }
  &:last-of-type {
    margin-right: 0;
  }
  &:before {
    content: none;
  }
  &:after {
    content: '';
    position: absolute;
    top: 50%;
    left: 30px;
    display: block;
    width: 20px;
    height: 15px;
    background-repeat: no-repeat;
    background-position: center right;
    background-size: contain;
    transform: translateY(-50%);
    @media (max-width: 575px) {
      left: 15px;
      width: 15px;
    }
  }
  &.phone {
    &:after {
      background-image: url(${phoneIcon});
    }
  }
  &.email {
    &:after {
      background-image: url(${emailIcon});
    }
  }
  &.download {
    &:after {
      background-image: url(${downloadIcon});
    }
  }
`

const ContactLink = styled(ButtonLinkTransparent)`
  font-size: 24px;
  padding-left: 0;
  margin-right: 30px;
  @media (max-width: 575px) {
    font-size: 18px;
    margin-right: 10px;
  }
  &:last-of-type {
    margin-right: 0;
  }
  &:before {
    @media (max-width: 575px) {
      top: 45%;
    }
  }
`

const Contact = (props) => {
  return (
    <ContactWrapper>
      <Container>
        <Row>
          <Col xs={12}>
            <ContactTitle color="#91BD24">
              <span>Kontakt do biura</span>
            </ContactTitle>
          </Col>
        </Row>
        <Row>
          <ContactDescOuterWrapper xs={12} lg={7}>
            <ContactInnerTitle color="#00975F">
              Masz <span>pytania</span>?
            </ContactInnerTitle>
            <div>
              <ContactButtonLink
                href="tel:+48519877700"
                className="phone"
                title="Zadzwoń do nas"
              >
                +48 519-877-700
              </ContactButtonLink>
              <ContactButtonLink
                href="mailto:kontakt@misjakieszonkowe.pl"
                className="email"
                title="Napisz do nas"
              >
                kontakt@misjakieszonkowe.pl
              </ContactButtonLink>
            </div>
          </ContactDescOuterWrapper>
          <ContactDescOuterWrapper xs={12} lg={5}>
            <ContactInnerTitle color="#00975F">
              Masz <span>wątpliwości</span>?
            </ContactInnerTitle>
            <div>
              <ContactButtonLink
                href={withPrefix('/docs/Misja_Kieszonkowe_FAQ.pdf')}
                className="download"
                target="_blank"
              >
                Pobierz listę FAQ
              </ContactButtonLink>
            </div>
          </ContactDescOuterWrapper>
          <ContactDescOuterWrapper xs={12}>
            <ContactInnerTitle>Regulaminy</ContactInnerTitle>
            <div>
              <ContactLink
                target="_blank"
                href={withPrefix(
                  '/docs/Regulamin_Programu_Misja_Kieszonkowe.pdf'
                )}
                title="Regulamin programu"
              >
                Regulamin programu
              </ContactLink>
              <ContactLink
                target="_blank"
                href={withPrefix(
                  '/docs/Regulamin_Konkursu_Misja_Kieszonkowe.pdf'
                )}
                title="Regulamin konkursu"
              >
                Regulamin konkursu
              </ContactLink>
            </div>
          </ContactDescOuterWrapper>
        </Row>
      </Container>
    </ContactWrapper>
  )
}

export default Contact
