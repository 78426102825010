import styled from 'styled-components'
import { Col } from 'reactstrap'

import { ButtonLink } from '../elements'
import { FormPromptinnerWrapper } from './FormPromptinnerWrapper'

import formsBG from '../../images/forms/forms_bg.svg'
import formNoteLogoIcon from '../../images/forms/form_note_logo_icon.svg'

export const PageInnerWrapper = styled.div`
  overflow: hidden;
  background-image: url(${formsBG});
  background-repeat: no-repeat;
  background-position: top right;
  background-size: 100% auto;
  @media (max-width: 767px) {
    background-position: top center;
    background-size: 200% auto;
  }
  @media (max-width: 575px) {
    background-size: 240% auto;
  }
`

export const Title = styled.h1`
  color: ${(props) => props.theme.colorGreenLight};
  font-size: 46px;
  font-weight: 700;
  margin-top: 50px;
  margin-bottom: 30px;
  @media (max-width: 767px) {
    font-size: 34px;
    margin-top: 20px;
    margin-bottom: 20px;
  }
`

export const SubTitle = styled.p`
  color: ${(props) => props.theme.colorGreenLight};
  font-size: 16px;
  font-weight: 300;
  margin-bottom: 30px;
  @media (max-width: 767px) {
    font-weight: 400;
  }
`

export const Button = styled(ButtonLink).attrs({
  type: 'submit',
})`
  width: 100%;
  margin-top: 15px;
`

export const DropzoneButton = styled(ButtonLink)`
  width: 100%;
  margin-top: 15px;
`

export const Form = styled.form`
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
`

export const FormItemWrapper = styled(Col)`
  &:nth-child(odd) {
    ${FormPromptinnerWrapper} {
      &:before {
        background-color: rgba(255, 255, 255, 0.87);
      }
    }
  }
`

export const FormBottomWrapper = styled(Col)`
  padding-top: 15px;
  padding-bottom: 50px;
`

export const CheckboxWrapper = styled(Col)`
  margin-bottom: 10px;
  .custom-control-label {
    font-size: 17px;
    font-weight: 400;
    padding-left: 8px;
    a {
      color: ${(props) => props.theme.colorGreenLight};
      font-weight: 700;
    }
  }
  .custom-control-input {
    &:checked {
      & ~ .custom-control-label {
        &:before {
          border-color: ${(props) => props.theme.colorGreenLight};
          background-color: ${(props) => props.theme.colorGreenLight};
        }
      }
    }
  }
`

export const NotesWrapper = styled(Col)`
  font-family: ${(props) => props.theme.fontHelveticaNeue};
  font-size: 11px;
  font-weight: 400;
  @media (min-width: 768px) {
    padding-left: 10%;
    padding-right: 10%;
  }
  @media (max-width: 767px) {
    padding-top: 20px;
  }
`

export const NotesIcon = styled.div`
  background-image: url(${formNoteLogoIcon});
  background-repeat: no-repeat;
  background-position: top left;
  background-size: contain;
  width: 58px;
  height: 20px;
  margin-bottom: 10px;
`

export const FormElementWrapper = styled.div`
  position: relative;
  height: 100%;
  padding-top: 5px;
  padding-left: 15px;
  padding-right: 15px;
  padding-bottom: 5px;
  ${(props) =>
    props.active === props.id &&
    `
    z-index: 20;
  `}
  @media (max-width: 575px) {
    padding-left: 0;
  }
  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    display: block;
    width: 10000px;
    background-color: rgba(145, 189, 36, 0.21);
    transition: ease 0.5s all;
    z-index: 1;
    opacity: 0;

    ${(props) =>
    props.active === props.id &&
    `
      opacity: 1;
    `}
    @media (max-width: 991px) {
      content: none;
    }
  }
  &.tranparent {
    &:before {
      content: none;
    }
  }
  &.phone {
    &:after {
      content: '+48';
      position: absolute;
      left: 15px;
      bottom: 16px;
      color: #6c6c6c;
      font-family: ${(props) => props.theme.fontBNPPSansCondensed};
      font-size: 30px;
      font-weight: 400;
      @media (max-width: 575px) {
        left: 0;
      }
    }
    .form-control {
      width: calc(100% - 40px);
      transform: translateX(40px);
      @media (max-width: 991px) {
        width: calc(100% - 65px);
      }
    }
  }
  &.class {
    .select-outer {
      @media (max-width: 991px) {
        width: 100%;
      }
    }
    &.level {
    }
    &.name {
      @media (max-width: 991px) {
        padding-left: 0;
        padding-right: 25px;
        transform: translateX(-15px);
      }
    }
  }
  .form-control,
  .select-outer {
    @media (max-width: 991px) {
      width: calc(100% - 25px);
    }
  }
`

export const FormPromptWrapper = styled(Col)`
  display: flex;
  align-items: center;
  @media (max-width: 991px) {
    display: none;
  }
`

export const FormPrompt = styled.p`
  position: relative;
  margin-bottom: 0;
  z-index: 5;
`
