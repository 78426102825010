import React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'
import { Row, Col } from 'reactstrap'

import { ButtonLinkTransparent } from '../../components/elements'

const BackWrapper = styled(Row)`
  @media (min-width: 992px) {
    display: none;
  }
`

const BackInnerWrapper = styled(Col)``

const BackButtonLink = styled(ButtonLinkTransparent)`
  font-family: ${(props) => props.theme.fontBNPPSansCondensed};
  font-size: 26px;
  font-weight: 400;
  margin-top: 30px;
  padding-left: 20px;
  padding-right: 0;
  &:hover,
  &:focus {
    &:before {
      left: -5px;
    }
  }
  &:before {
    left: 0;
    right: auto !important;
    transform: rotate(180deg) translateY(50%);
  }
`

const Back = (props) => {
  const { link, name, className } = props
  return (
    <BackWrapper className={className}>
      <BackInnerWrapper xs={12}>
        <BackButtonLink as={Link} to={link} title={name}>
          {name}
        </BackButtonLink>
      </BackInnerWrapper>
    </BackWrapper>
  )
}

export default Back
