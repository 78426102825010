import axios from 'axios'
import React from 'react'
import { useEffect, useState } from 'react'
import { IdProvider } from 'react-use-id-hook'
import { Container, Row, Col } from 'reactstrap'
import styled, { keyframes } from 'styled-components'

import Layout from '../../../components/layout'
import SEO from '../../../components/seo'
import { PageInnerWrapper, Title } from '../../../components/elements/forms'
import Back from '../../../components/elements/back'
import Contact from '../../../components/teachers/contact'

const loadingSpinnerAnimation = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`

export const LoadingSpinner = styled.div`
  border-radius: 50%;
  width: 10em;
  height: 10em;
  margin: 30px auto;
  font-size: 10px;
  position: relative;
  text-indent: -9999em;
  border-top: 1.1em solid rgba(255, 255, 255, 0.2);
  border-right: 1.1em solid rgba(255, 255, 255, 0.2);
  border-bottom: 1.1em solid rgba(255, 255, 255, 0.2);
  border-left: 1.1em solid ${(props) => props.theme.colorGreenLight};
  transform: translateZ(0);
  animation: ${loadingSpinnerAnimation} 1.1s infinite linear;

  &:after {
    border-radius: 50%;
    width: 10em;
    height: 10em;
  }
`

const ActivateClassPage = ({ location }) => {
  const params = new URLSearchParams(location.search)
  const id = params.get('id')
  const email = params.get('email')
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    axios
      .post(`${process.env.GATSBY_MK_API_ACTIVATE_CLASS_URL}`, {
        id,
        email,
      })
      .then((resp) => {
        if (resp.status === 200) {
          setIsLoading(false)
        }
      })
  }, [])

  return (
    <IdProvider>
      <SEO title="Zgłoszenie klasy do programu - Strefa nauczyciela" />
      <Layout>
        <PageInnerWrapper>
          <Container>
            <Back
              link="/strefa-nauczyciela/"
              name="Powrót do strefy nauczyciela"
            />
            <Row>
              <Col md={12}>
                <Title>Zgłoszenie klasy do programu</Title>

                {isLoading && (
                  <>
                    <p>Ładowanie, proszę czekać.</p>
                    <LoadingSpinner />
                  </>
                )}

                {!isLoading && (
                  <p>
                    Dziękujemy za poprawną rejestrację w Programie Misja
                    Kieszonkowe.
                    <br />
                    Niebawem otrzymasz od nas wiadomość e-mail z materiałami
                    edukacyjnymi zakresu edukacji finansowej dla Twojej klasy.
                  </p>
                )}
              </Col>
            </Row>
          </Container>
        </PageInnerWrapper>
        <Contact />
      </Layout>
    </IdProvider>
  )
}

export default ActivateClassPage
