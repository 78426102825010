import React from 'react'
import styled from 'styled-components'

export const FormPromptinnerWrapper = styled.div`
  position: relative;
  height: 100%;
  display: flex;
  align-items: center;
  color: #707070;
  font-size: 16px;
  font-weight: 300;
  padding-left: 30px;
  padding-right: 30px;
  transition: ease 0.5s all;
  z-index: 1;

  ${(props) =>
    props.active === props.current &&
    `
    
    color: ${props.theme.colorWhite};
  `}
  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    display: block;
    width: 10000px;
    background-color: ${(props) => props.theme.colorWhite};
    transition: ease 0.5s all;

    ${(props) =>
    props.active === props.current &&
    `
      background-color: ${props.theme.colorGreenBright} !important;
      border-radius: 10px;
    `}
    @media (max-width: 991px) {
      content: none;
    }
  }
  &.first {
    &:before {
      border-top-left-radius: 10px;
    }
  }
  &.last {
    &:before {
      border-bottom-left-radius: 10px;
    }
  }
`
